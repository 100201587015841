import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import i18n from "./i18n/index";
// import "@/mixins/microsoft";
// import "@/mixins/nylas";
// import "@/mixins/logger";
// import "@/mixins/access";
// import "@/mixins/sidepanels";
// import "@/mixins/user";
// import "@/helpers/index";
import "@vuepic/vue-datepicker/dist/main.css";
import userMixin from "@/mixins/user";
import sidepanelMixin from "@/mixins/sidepanels";
import VueHtmlToPaper from "vue-html-to-paper";
// import browserDetect from 'vue-browser-detect-plugin';
import detectBrowser from "vue-detect-browser";
import socketPlugin from "./plugins/socket";
import VueExcelXlsx from "vue-excel-xlsx";
import nMapper from "./plugins/numbersMapper";
import lfMapper from "./plugins/listingFieldsMapper";
import TooltipDirective from "./directives/vTooltip";
import { GoogleMap, Marker as TheMarker } from "vue3-google-map";
import ToastPlugin from "./plugins/toast";
import VueGoogleMaps from "@fawmi/vue-google-maps";

// Create Vue app
const app = createApp(App);

app.component("GoogleMap", GoogleMap);
app.component("TheMarker", TheMarker);

// Use mixins
app.mixin(userMixin);
app.mixin(sidepanelMixin);

// Use plugins
app.use(VueExcelXlsx);
app.use(detectBrowser);

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    console.log("main.js error: ", error);
    // if (error.response.status === 401 || error.response.status === 502) {
    //     await store.dispatch('logout');
    //     await router.push('/login');
    // }
    // if (error.response.status === 429) {
    //     toast(toastContent, toastOptions);
    // }
    return error.response;
  },
);
app.config.globalProperties.$http = axios;

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=no", "scrollbars=no"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
    "https://cdn.jsdelivr.net/npm/vuetify@3.x/dist/vuetify.min.css",
    "/assets/App.scss",
    "/assets/ListingsFullDetail.scss",
    "/assets/GmapMap.scss",
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: "testing",
  // windowTitle: window.document.title, // override the window title
};
app.use(VueHtmlToPaper, options);

let socketUrl = window.location.hostname;
if (socketUrl === "localhost") socketUrl = "http://localhost:4000";

app.use(socketPlugin, {
  connection: socketUrl,
  options: {
    path: "/socket",
    transports: ["websocket"],
  },
});

// Use router, store, vuetify, and i18n
app.use(router);
app.use(store);
app.use(vuetify);
app.use(nMapper);
app.use(lfMapper);
app.use(i18n);
app.use(ToastPlugin);
app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDcKJ-XoY_Jdp5mPgRkcyn0E8jW83LFBCU",
    libraries: "places",
  },
});
// custom directives
app.directive("tooltip", TooltipDirective);

console.log("Mounting Vue App");

// Add browserDetect after Vue has been initialized
try {
  app.mount("#app");
} catch (error) {
  console.error("Error mounting Vue app:", error);
}
